import { Badge, Button, Container, Spinner, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { FaBan, FaCheckCircle } from "react-icons/fa";
import { useEffect, useState } from 'react';

const List =(props) =>{
  const productsState = useSelector((state) => state.kioskInventory)
  const [productList , setProductList] = useState([] )
  const [query, setQuery] = useState('');

  const handleQuery = (evt) => {
      setQuery(evt.currentTarget.value)
  }
  const handleEdit = (evt) => {
    const indexGuard = evt.target.dataset.index;
    props.handleEdit(productList[indexGuard])
  }
  const handleShowList = (evt) => {
    const indexGuard = evt.target.dataset.index;
    props.handleShowList(productList[indexGuard])
  }
  useEffect(()=>{
    let listBatches = productsState.products.filter((b)=> {
        const filterValidation = (query==='' 
          || (b.category?.name?.includes(query) 
          || b.id == query 
          || b.code?.includes(query) 
          || b.name.includes(query)
          || b.description.includes(query)
        )) 
        return filterValidation
    })
    setProductList(listBatches)
  }, [query])

  if(props.loading){
    return <Container className={"text-center justify-content-center"}>
      <Spinner className={"text-center"} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  }
  return <>
  <input type='search' onInput={handleQuery} defaultValue={query} className='form-control my-3' placeholder='Buscar unidad por ID, serial, categoría y descripción' />
  <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>ID</th>
      <th>Categoría</th>
      <th>Serial</th>
      <th>Nombre</th>
      <th>Descripción</th>
      <th>Precio de Venta</th>
      <th>Impuesto aplicable</th>
      <th>Unidades Disponibles</th>
      <th>Estado</th>
      <th>Acciones</th>
    </tr>
    </thead>
    <tbody>
    {productList.map((g, idx)=>(<tr key={idx}>
      <td>{g.id}</td>
      <td>{g.category?.name}</td>
      <td>{g.code}</td>
      <td>{g.name}</td>
      <td>{g.description}</td>
      <td>$ {g.sale_price}</td>
      <td>{g.tax?.name} ({g.tax?.rate*100}%)</td>
      <td>
        <Badge bg="success">
          {productsState.units?.filter((u)=>u.product_id === g.id && u.active && !u.sold && (u.expiration == null || new Date(u.expiration) >= new Date)).length}
           {" "} Und Disponibles
        </Badge><br/>
        <Badge bg="secondary">
          {productsState.units?.filter((u)=>u.product_id === g.id && !u.active && !u.sold && (u.expiration == null || new Date(u.expiration) >= new Date)).length}
           {" "} Und Inactivas
        </Badge><br/>
        <Badge bg="danger">
          {productsState.units?.filter((u)=>u.product_id === g.id && !u.sold &&  new Date(u.expiration) < new Date).length}
           {" "} Und Vencidas
        </Badge><br/>
      </td>
      <td>{g.active ? <FaCheckCircle className="text-success" />: <FaBan className="text-danger" /> }</td>
      <td>
        <Button variant={"info"} data-index={idx} className={"text-light mx-1"} onClick={handleShowList}>Ver Unidades</Button>
        <Button variant={"warning"} data-index={idx} className={"text-light  mx-1"} onClick={handleEdit}>Editar</Button>
      </td>
    </tr>))}
    </tbody>
  </Table>
  </>
}

export default List