import { Badge, Button, Form, Spinner, Table } from 'react-bootstrap'
import React from 'react'

const TableBatches = ({ current, batchList, saveBatchInfo, saving, newRecord, setNewRecord,  editId, setEditId, editBatch})=> {
  const handleEdit = (evt) => {
    setEditId(evt.target.dataset.id)

    setNewRecord(false)
  }

  return <Table striped bordered hover responsive>
    <thead>
    <tr>
      <th>ID</th>
      <th>Precio Compra</th>
      <th>Cantidad</th>
      <th>Fecha de Vencimiento</th>
      <th>Estado</th>
      {editBatch && <th>Acciones</th>}
    </tr>
    </thead>
    <tbody>
    {batchList.map((g, idx) => {
      return <tr key={idx}>
        <td>
          {g.id}
        </td>
        <td>
          {editId != g.id && g.price }
          {editId == g.id && <Form.Control type="number" name={"price"} onChange={editBatch} defaultValue={g.price} />}
        </td>
        <td>
          {editId != g.id && g.qty}
          {editId == g.id && <Form.Control type="number" min="0" name={"quantity"} onChange={editBatch} defaultValue={g.qty} />}
        </td>
        <td>
          {editId != g.id && g.expiration}
          {editId == g.id && <Form.Control type="date" name={"expiration"} onChange={editBatch} defaultValue={g.expiration} />}
        </td>
        <td>
          {editId != g.id && <Badge bg={g.active=="1" ? 'success' : 'danger'}>{g.active=="1" ? 'activo' : 'inactivo'}</Badge> }
          {editId == g.id && <div>
            <label><input type="radio" name={"active"} value={1} onClick={editBatch} defaultChecked={g.active=="1"} /> <Badge bg={"success"}>Activo</Badge></label>
            <br/><label><input type="radio" name={"active"} value={0} onClick={editBatch} defaultChecked={g.active!="1"} /> <Badge bg={"danger"}>Inactivo</Badge></label>
          </div>}
        </td>
        {editBatch && <td>
          {editId != g.id && <Button variant={'warning'} data-id={g.id} className={'text-light  mx-1'}
                                     onClick={handleEdit}>Editar</Button>}
          {editId == g.id && <Button variant={'success'} data-id={g.id} disabled={saving} className={'text-light  mx-1'}
                                     onClick={saveBatchInfo}>{saving && <Spinner />} Guardar</Button>}

        </td>}
      </tr>
    })}
    {newRecord && editId === 0 && <tr>
      <td>
        ?
      </td>
      <td>
        <Form.Control type="number" name={"price"} placeholder={"Precio"} onChange={editBatch} />
      </td>
      <td>
        <Form.Control type="text" name={"code_complement"} onChange={editBatch} maxlength="8" placeholder={"Serial"} />
      </td>
      <td>
        <Form.Control type="date" style={{width:"49%", display:"inline", marginRight:"5px"}} min={0} name={"expiration"} onChange={editBatch} placeholder={"Fecha de Expiración"}  />
        <Form.Control type="number" style={{width:"49%", display:"inline"}} className="col-6" min={1} name={"quantity"} onChange={editBatch} placeholder={"Cantidad"}  />
      </td>
      <td>
        <div>
          <label><input type="radio" name={"active"} value={1} onChange={editBatch} defaultChecked={true} /> <Badge bg={"success"}>Activo</Badge></label>
          <br/><label><input type="radio" name={"active"} value={0} onChange={editBatch} defaultChecked={false} /> <Badge bg={"danger"}>Inactivo</Badge></label>
        </div>
      </td>
      <td>
        <Button variant={'success'} disabled={saving} className={'text-light  mx-1'}
                onClick={saveBatchInfo}>{saving && <Spinner />} Guardar</Button>

      </td>
    </tr>}
    </tbody>
  </Table>
}

export default TableBatches