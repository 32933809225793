import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { get } from "../../store/InvoicesKioskSlice"
import List from "./List"
import axios from "axios"

const KioskInvoices = ()=>{
    const dispatch = useDispatch()
    const userState = useSelector((state) => state.user)
    const [loading, setLoading] = useState(true)
    const configs = useMemo(()=>({
        headers:{
          'content-type': 'application/json',
          'Authorization': `Bearer ${userState.user.token}`
        }
      }), [userState])
    
      const getData = useCallback(async ()=>{
        setLoading(true)
        const response = await axios.get(process.env.REACT_APP_USER_API_URL + "/kiosk/invoice", configs)
        dispatch(get(response.data))
        setLoading(false)
      },[dispatch, configs])


      useEffect(()=>{
        setLoading(true)
        let promise = getData()
        promise.then(()=>{
          setLoading(false)
        })
      },[getData,userState.user.token])
    return <>
        <List loading={loading} />
    </>
}

export default KioskInvoices