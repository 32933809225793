import React, { useState } from 'react'
import * as Yup from 'yup'
import { Button, FormControl, FormGroup, FormLabel, FormCheck,Spinner } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'

const FormGuard = (props) => {
  const [formValues, setFormValues] = useState({
    name: props.name,
    active: props.active??true,
    credit: props.credit??true,
    calculator: props.calculator??true,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Valor requerido").max(50, "Nombre debe ser máximo de 20 caracteres"),
    active: Yup.boolean().required("Valor requerido"),
    calculator: Yup.boolean().required("Valor requerido"),
    credit: Yup.boolean().required("Valor requerido")
  });
  const handleAutofillChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = async (values, { setSubmitting, isSubmitting }) => {
    setSubmitting(true)
    setFormValues(values)
    await props.handleSubmit(values)
    setSubmitting(false)
  }

  return <Formik
    initialValues={formValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
    {({ handleSubmit, isSubmitting, errors, touched }) => {
      return <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-3" controlId="login-form">
          <FormLabel>Forma de Pago</FormLabel>
          <FormControl
            type="text"
            as={Field}
            name="name"
            controlid="name"
            placeholder="Nombre"
            autoComplete={"off"}
            onInput={handleAutofillChange}
            isInvalid={touched.name && !!errors.name}
          />
          <FormControl.Feedback type="invalid">
            {errors.name}
          </FormControl.Feedback>
        </FormGroup>
        
        <FormGroup className="mb-3" controlId="credit-account-form">
          <FormLabel>Cancelado | Deuda</FormLabel>
          <Field name="credit">
            {({ field, form }) => (
              <FormCheck
                {...field}
                type="switch"
                id="credit"
                name="credit"
                controlid="credit"
                placeholder="credit"
                value={field.value}
                defaultChecked={field.value !== 0}
                onChange={(event) => {
                  form.setFieldValue(event.target.name, event.target.checked);
                }}
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.credit && !!errors.credit}
              />
            )}
          </Field>
          <FormControl.Feedback type="invalid">
            {errors.credit}
          </FormControl.Feedback>
        </FormGroup>

        <FormGroup className="mb-3" controlId="calculator-account-form">
          <FormLabel>Necesita Calculadora</FormLabel>
          <Field name="calculator">
            {({ field, form }) => (
              <FormCheck
                {...field}
                type="switch"
                id="calculator"
                name="calculator"
                controlid="calculator"
                placeholder="calculator"
                value={field.value}
                defaultChecked={field.value !== 0}
                onChange={(event) => {
                  form.setFieldValue(event.target.name, event.target.checked);
                }}
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.calculator && !!errors.calculator}
              />
            )}
          </Field>
          <FormControl.Feedback type="invalid">
            {errors.credit}
          </FormControl.Feedback>
        </FormGroup>

        <FormGroup className="mb-3" controlId="active-account-form">
          <FormLabel>Estado</FormLabel>
          <Field name="active">
            {({ field, form }) => (
              <FormCheck
                {...field}
                type="switch"
                id="active"
                name="active"
                controlid="active"
                placeholder="active"
                value={field.value}
                defaultChecked={field.value !== 0}
                onChange={(event) => {
                  form.setFieldValue(event.target.name, event.target.checked);
                }}
                autoComplete={"off"}
                onInput={handleAutofillChange}
                isInvalid={touched.active && !!errors.active}
              />
            )}
          </Field>
          <FormControl.Feedback type="invalid">
            {errors.active}
          </FormControl.Feedback>
        </FormGroup>
        {isSubmitting && <Spinner />}
        <Button variant="success" type="submit" disabled={isSubmitting}>
          Almacenar
        </Button>
      </Form>
    }
    }
  </Formik>

}

export default FormGuard